import './NoResult.css'
import SearchOffIcon from '@mui/icons-material/SearchOff'

function NoResult () {
  return (
    <div class="no-result">
      <SearchOffIcon fontSize="large" />
      Aucun résultat...
    </div>
  )
}

export default NoResult
