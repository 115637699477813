import './ExplanationText.css'
import ReactCountryFlag from "react-country-flag"

function ExplanationText () {
  const ReactCountryFlagStyle = { display: 'block', fontSize: '1.5rem', marginBottom: '1rem' }
  return (
    <div className="explanation-text">
      <div>
        <ReactCountryFlag countryCode="fr" svg style={ ReactCountryFlagStyle } />
        Utilisez le moteur de recherche des sites Internet des RCS (ou équivalent) en Europe et dans le monde.
        Effectuez votre recherche en précisant la zone géographique et le pays.
      </div>
      <div>
        <ReactCountryFlag countryCode="gb" svg style={ ReactCountryFlagStyle } />
        We provide you with a specially developed engine leading to websites about foreign registers.
        Specify continent and country.
      </div>
      <div>
        <ReactCountryFlag countryCode="es" svg style={ ReactCountryFlagStyle } />
        Le proponemos un buscador de registros mercantiles en Europa y en el mundo accesibles en la web.
        Precisen la zona geografica y el pais.
      </div>
    </div>
  )
}

export default ExplanationText
